.login {
  width: 100vh;
  height: 100vh;
  background-color: #f8f2f5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginWrapper {
  width: 70%;
  height: 70%;
  display: flex;
}

.loginLeft,
.loginRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.loginLogo {
  font-size: 50px;
  font-weight: 800;
  color: #1775ee;
  margin: 10px;
}

.loginDesc {
  font-size: 24px;
}

label {
  /* display: flex; */
  flex-direction: column;
}

.loginBox {
  height: 200px;
  padding: 20px;
  background-color: #f8f2f5;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.loginInput {
  height: 50px;
  border-radius: 10px;
  border: 1px solid grey;
  font-size: 20px;
  padding-left: 20px;
}

.loginInput:focus {
  outline: none;
}

.loginButton {
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #1775ee;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}

.loginForgot {
  text-align: center;
  color: #1775ee;
}

.loginRegisterButton {
  width: 60%;
  align-self: center;
  height: 50px;
  border-radius: 10px;
  border: none;
  background-color: #42b728;
  color: white;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}


.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.login-container .container {
  padding-left: 7%;
  padding-right: 7%;
  height: 100%;
}

.login-container .container h1 {
  font-size: 2.5rem;

}

h1 {
  font-family: 'Lobster', cursive;
}

.login-container .container form {
  display: flex;
  height: 80%;
  flex-direction: column;
  justify-content: space-around;
  align-self: center;

}


input [type="text"],
input[type="password"],
input[type="email"],
[type="username"] {
  padding-left: 1px;
  padding-right: 1px;
  height: 40px;
  border-radius: 5px;
  border: .5px solid rgb(143, 143, 143);
  font-size: 15px;
}

label {
  display: flex; 
  flex-direction: column;
}

.login-container button {
  height: 40px;
  font-weight: bold;
  width: 100%;
  font-size: 15px;
  background-color: #2b89f4;
  color: rgb(255, 255, 255);
}

.login-container button:hover {
  background-color: rgba(0, 0, 0, 0.808);
  cursor: pointer;
}

.login-container button:focus {
  outline: none !important;
}

.spinner {
  animation: spinner infinite .9s linear;
  height: 90%;
}

.spinner:focus {
  border: none;
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.form-error {
  color: #F42B4B;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .login-container {
    justify-content: center;
    background-image: none;

  }

  .cardlogin {
    width: 100%;
    align-self: center;
    margin-left: auto;
  }

}

@media screen and (max-width: 350px) {
  .card {
    width: 100%;
  }

}